.cell {
	-moz-box-flex: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	background-color: none;
	border: none;
	box-flex: 1;
	flex: 1 1 auto;
	margin: 10px;
	padding: 10px;
	text-align: center;
}

.row {
	-moz-box-align: center;
	-moz-box-orient: horizontal;
	-moz-box-pack: center;
	-webkit-box-align: center;
	-webkit-box-orient: horizontal;
	-webkit-box-pack: center;
	align-items: center;
	background-color: none;
	box-align: center;
	box-orient: horizontal;
	box-pack: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.styles_closeButton__20ID4 {
	background-color: transparent;
	border: none;
	display: none;
	padding: 0;
	position: absolute;
	right: 14px;
	top: 14px;
}

.styles_modal__gNwvD {
	background: transparent;
	background-clip: padding-box;
	box-shadow: 0 0 0 0 rgba(106, 103, 130, 0.25);
	margin: auto;
	max-width: 1500px;
	padding: 1.2rem;
	position: relative;
}

.styles_overlay__CLSq- {
	align-items: flex-start;
	background: #2dbeff;
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	opacity: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 1.2rem;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 2000;
}

img {
	max-width: 100%;
}

/*react loader quotes css to*/

.ghBIEp {
	position: static !important;
}

.hEhxDV {
	width: 99% !important;
	background-color: #f1f1f1 !important;
}

.kqxiXT {
	top: 300px !important;
	background-color: #f1f1f1 !important;
}