.react-card-back {
  position: relative!important;
}

.react-card-flip {
  padding-bottom: 20px;
  padding-top: 20px;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.react-card-front {
  margin-top: -50px;
}

.react-phone-number-input__country--native {
  -ms-flex-align: center;
  -ms-flex-item-align: stretch;
  align-items: center;
  align-self: stretch;
  display: flex;
  margin-right: 1em;
  position: relative;
}

.react-phone-number-input__country-select-arrow {
  -o-transition: color .1s;
  -webkit-transition: color .1s;
  border-left-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: .35em .2em 0;
  color: #495057;
  content: "";
  display: block;
  height: 7px;
  margin-bottom: .1em;
  margin-left: .3em;
  margin-top: .3em;
  opacity: 0.7;
  transition: color .1s;
  width: 7px;
}

.react-phone-number-input__error {
  color: #d50000!important;
  font-size: .8rem;
  font-weight: bolder;
  line-height: 1.3em;
}

.react-phone-number-input__icon-image {
  margin-top: -10px;
  max-height: 100%;
  max-width: 100%;
}

.react-phone-number-input__input--invalid, .react-phone-number-input__input--invalid:focus {
  border-color: #eb2010;
}

.react-phone-number-input__input:focus {
  border-color: #ffc90d;
}

.react-phone-number-input__phone {
  -ms-flex: 1 1;
  color: #495057;
  flex: 1 1;
  min-width: 0;
}

.react-phone-number-input__row {
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  margin-top: 30px!important;
}

/* captcha css */

.grecaptcha-badge {
  position: unset!important;
}