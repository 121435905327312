.LoaderButton .spinning .glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
