.verify-cell {
  -moz-box-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  border: none;
  box-flex: 1;
  flex: 1 1 auto;
  padding: 10px;
  text-align: center;
}

.verify-img {
  max-width: 30%;
}

.verify-row {
  -moz-box-align: center;
  -moz-box-orient: horizontal;
  -moz-box-pack: center;
  -webkit-box-align: center;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  align-items: center;
  background-color: none;
  box-align: center;
  box-orient: horizontal;
  box-pack: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}